import React from "react";
import { useLocation } from "react-router-dom";

export default function NotFound() {
  const location = useLocation();

  return (
    <div className="notfound">
      <h2 className="error-title">Not Found</h2>
      <p className="error-content">
        <span className="font-bold">Error:&nbsp;</span>The requested
        address&nbsp;
        <span className="font-bold">'{location.pathname}'</span> was not found
        on this server.
      </p>
    </div>
  );
}
