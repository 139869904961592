import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

export default function Coupon() {
  const logo = useRef();
  const headTitle = useRef();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState();

  const methods = {
    // クーポン情報取得
    getCouponData: async () => {
      if (location.state?.data) {
        setData(location.state.data);
        return;
      }
      const res = await fetch(
        `${process.env.REACT_APP_API_HOST}?houjinCode=${params.houjinCode}`
      );
      const jsonRes = await res.json();
      // 取得に失敗した場合ログインページに遷移
      if (jsonRes.error) {
        navigate("/");
        return;
      }
      setData(jsonRes);
    },
    // スクロールしてもタイトルを一番トップに表示
    stickyTitle: () => {
      if (window.pageYOffset > headTitle.current.offsetTop) {
        headTitle.current.classList.add("fix");
        logo.current.style.marginBottom = `${headTitle.current.clientHeight}px`;
      } else {
        headTitle.current.classList.remove("fix");
        logo.current.style.marginBottom = "0px";
      }
    },
  };

  useEffect(() => {
    // クーポン情報取得
    methods.getCouponData();
    // 背景色を変更
    document.body.classList.add("coupon");
    // スクロールイベント追加
    window.addEventListener("scroll", methods.stickyTitle);

    return () => {
      // 背景色を戻す
      document.body.classList.remove("coupon");
      // スクロールイベント削除
      window.removeEventListener("scroll", methods.stickyTitle);
    };
    // eslint-disable-next-line
  }, []);

  return (
    data && (
      <div id="container_coupon">
        <div className="inner">
          <div className="head_logo" ref={logo}>
            <img src="/images/logo.png" alt="logo" />
          </div>

          <div className="fix_box" ref={headTitle}>
            <div className="group">
              <p>
                {data.name}
              </p>
              <p className="description">
                ご本人様と一緒のご来店でご家族様もご利用いただけます。
              </p>
            </div>
            <hr />
            <div className="scan">
              <p className="item-title">店舗用スキャンコード</p>
              <p className="barcode">
                <img
                  src={`/upload/barcode/${data.group_id}/${data.barcode_img}`}
                  alt="barcode"
                />
              </p>
            </div>
            {data.teikei_code && (
              <>
                <hr />
                <div className="code">
                  <p className="item-title">提携コード</p>
                  <span className="code-box">{data.teikei_code}</span>
                </div>
              </>
            )}
          </div>

          <div className="coupon_img">
            <img
              id="coupon"
              src={`/upload/coupon/${data.group_id}/${data.coupon_img}`}
              alt="coupon"
            />
          </div>
        </div>
      </div>
    )
  );
}
