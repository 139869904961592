import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginHoujin from "./pages/login_houjin";
import LoginSchool from "./pages/login_school";
import Coupon from "./pages/coupon";
import NotFound from "./pages/404";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginHoujin />} exact></Route>
        <Route path="/school" element={<LoginSchool />} exact></Route>
        <Route path="/coupon/:houjinCode" element={<Coupon />} exact />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
