import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // ログイン失敗する時エラーメッセージを表示
  useEffect(() => {
    if (location.state && location.state.error) {
      setError(location.state.error);
      navigate(location.pathname, {});
    }
    // eslint-disable-next-line
  }, []);

  const methods = {
    // ログイン
    login: async () => {
      if (value) {
        setLoading(true);
        try {
          const res = await fetch(
            `${process.env.REACT_APP_API_HOST}?houjinCode=${value}`
          );
          const jsonRes = await res.json();
          setLoading(false);
          // 取得に失敗した場合ログインページに遷移
          if (jsonRes.error) {
            setError(jsonRes.error);
            return;
          }
          navigate(`/coupon/${value}`, {
            state: {
              data: jsonRes,
            },
          });
        } catch (e) {
          setLoading(false);
        }
      }
    },
    // enter key down(ログイン)
    onEnter: (e) => {
      if (e.key === "Enter") {
        methods.login();
      }
    },
  };

  return (
    <div id="container">
      <div className="inner">
        <img src="/images/logo.png" alt="logo" />
        <p className="input-text">
          クーポンコードを入力し、
          <br />
          割引クーポンを見る ＞ を押してください。
        </p>
        <div className="contact_item">
          <input
            className="login-input"
            placeholder="クーポンコード"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={methods.onEnter}
          ></input>
          {error && (
            <div className="error-message">
              ※{error}
            </div>
          )}
          <button
            className="login-btn"
            onClick={methods.login}
            onKeyDown={methods.onEnter}
            disabled={!value || loading}
          >
            割引クーポンを見る&nbsp;＞
          </button>
          <a
            className="link-btn"
            target="_blank"
            rel="noreferrer"
            href="https://www.aoki-style.com/feature/recruit/ultimate/"
          >
            究極の就活スーツに関してはコチラ！
          </a>
          <a
            className="link-btn"
            target="_blank"
            rel="noreferrer"
            href="https://www.aoki-style.com/feature/recruit/magazine/life/l_13/"
          >
            オンライン就活で映える魅せ方はコチラ！
          </a>
          <a
            className="link-btn"
            target="_blank"
            rel="noreferrer"
            href="https://www.aoki-style.com/feature/recruit/magazine/style/s_18/"
          >
            インターンシップの成功コーディネートはコチラ！
          </a>
        </div>
      </div>
    </div>
  );
}
